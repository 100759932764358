import type {
  NodeStatus,
  PlainNode,
  ActionNode,
  Node,
} from '@amalfi-analytics/ui/components/NodeGraph'
import type {
  InternalNodeType,
  Action,
  NodeType,
  NodeGroups,
} from './useAnalysis'
import { getStore } from '@/api/providers/store'
import { getStoreId } from '@/api/providers/store'
import { getAppIds } from '@/api/lib/http'
import {
  STATUS,
  STATUS_ERROR,
  STATUS_WAITING,
  STATUS_EXECUTING,
} from '@/api/providers'
import { ButtonProps } from '@amalfi-analytics/ui/components/Button'
import { AnalysisAction } from './analysisReducer'
import { ActionDispatch } from 'react'

export const getStoreAndAppByProjectId = async (
  projectId?: string,
): Promise<{ storeId: string; appId: string } | undefined> => {
  //Not a very elegant solution, but it works for now
  const appIds = await getAppIds()
  for (const appId of appIds) {
    const { store } = await getStore(appId)
    const storeId = getStoreId(appId)
    if (storeId && store.folders.find((folder) => folder.id === projectId)) {
      return { storeId, appId }
    }
  }
  return undefined
}

export function processLastNode(
  nodes: InternalNodeType[],
  status: NodeStatus,
): InternalNodeType[] {
  const lastNode = nodes.pop()
  if (lastNode === undefined) {
    return nodes
  }
  const actionNode = lastNode.type === 'action'
  if (actionNode) {
    lastNode.actions = lastNode.actions.map((action) => ({
      ...action,
      disabled: status.type === 'loading',
    }))
  }
  return [
    ...nodes,
    {
      ...lastNode,
      status,
    } as InternalNodeType,
  ]
}

export function parseNodesFromString(data: string): {
  nodes: InternalNodeType[]
  graphStateId?: string
  questionAnswered: boolean
  lastNode?: NodeType
} {
  const nodes: InternalNodeType[] = []
  let graphStateId: string | undefined
  let questionAnswered: boolean = false
  let lastNode: NodeType | undefined
  // Process graph_state_id if present
  const graphRegex = /<graph_state_id>\s*([\s\S]*?)\s*<\/graph_state_id>/i
  const graphMatch = data.match(graphRegex)
  if (graphMatch !== null) {
    graphStateId = graphMatch[1].trim()
    data = data.replace(graphMatch[0], '')
  }

  // Process complete nodes from the accumulator using a global regex
  const wrapperRegex =
    /<(pipeline_interpreter|question_interpreter|task_evaluator|reporter|action_selector)>([\s\S]*?)(<\/\1>|$)/gi
  let match
  while ((match = wrapperRegex.exec(data)) !== null) {
    // Determine if the node block is complete (has a closing tag)

    const tag = match[1]
    const content = match[2]
    const title = content.match(/<title>([\s\S]*?)(<\/title>|$)/i)?.[1]?.trim()
    let newNode: InternalNodeType
    switch (tag) {
      case 'pipeline_interpreter': {
        lastNode = 'experimentInterpretation'
        const analysisMatch = content.match(
          /<analysis_explanation>\s*([\s\S]*?)(<\/analysis_explanation>|$)/i,
        )
        const description =
          analysisMatch !== null ? analysisMatch[1].trim() : ''
        newNode = {
          type: 'plain',
          title,
          description,
          status: { type: 'normal' },
        }
        break
      }
      case 'question_interpreter': {
        lastNode = 'questionInterpretation'
        const thinkMatch = content.match(/<think>\s*([\s\S]*?)(<\/think>|$)/i)
        const description = thinkMatch !== null ? thinkMatch[1].trim() : ''
        newNode = {
          type: 'plain',
          title,
          description,
          status: { type: 'normal' },
        }
        break
      }
      case 'task_evaluator': {
        lastNode = 'evaluator'
        const thinkMatch = content.match(/<think>\s*([\s\S]*?)(<\/think>|$)/i)
        const questionAnsweredMatch = content.match(
          /<question_answered>\s*([\s\S]*?)(<\/question_answered>|$)/i,
        )
        const description = thinkMatch !== null ? thinkMatch[1].trim() : ''
        questionAnswered =
          questionAnsweredMatch !== null
            ? (JSON.parse(questionAnsweredMatch[1].trim()) as boolean)
            : false
        newNode = {
          type: 'plain',
          title,
          description,
          status: { type: 'normal' },
        }
        break
      }
      case 'reporter': {
        lastNode = 'report'
        const finalReportMatch = content.match(
          /<final_report>\s*([\s\S]*?)(<\/final_report>|$)/i,
        )
        const description =
          finalReportMatch !== null ? finalReportMatch[1].trim() : ''
        newNode = {
          type: 'plain',
          title,
          description,
          status: { type: 'normal' },
        }
        break
      }
      case 'action_selector': {
        lastNode = 'actionSelector'
        const thinkMatch = content.match(/<think>\s*([\s\S]*?)(<\/think>|$)/i)
        const actionsMatch = content.match(
          /<actions>([\s\S]*?)(<\/actions>|$)/i,
        )
        const description = thinkMatch !== null ? thinkMatch[1].trim() : ''

        let contentWithoutActions = content
        if (actionsMatch !== null) {
          contentWithoutActions = content.replace(actionsMatch[0], '')
        }

        const actionSelectorTitle = contentWithoutActions
          .match(/<title>([\s\S]*?)(<\/title>|$)/i)?.[1]
          ?.trim()

        if (actionsMatch !== null) {
          const { actionsArray } = parseActions(actionsMatch[1], nodes.length)
          newNode = {
            type: 'action',
            title: actionSelectorTitle,
            description,
            status: { type: 'normal' },
            actions: actionsArray,
          }
        } else {
          newNode = {
            type: 'plain',
            title: actionSelectorTitle,
            description,
            status: { type: 'normal' },
          }
        }
        break
      }
      default: {
        newNode = {
          type: 'plain',
          title,
          status: { type: 'normal' },
        }
        break
      }
    }
    nodes.push(newNode)
  }

  return { graphStateId, nodes, questionAnswered, lastNode }
}

// Updated parseActions method to capture one action at a time correctly.
function parseActions(
  response: string,
  nodeId: number,
): { actionsArray: Action[] } {
  const actionsArray: Array<Action & { parent_subset_id?: string }> = []

  // Extract all action blocks, including incomplete ones without closing tags
  const actionBlockRegex = /<action>([\s\S]*?)(<\/action>|$)/gi
  let actionMatch

  while ((actionMatch = actionBlockRegex.exec(response)) !== null) {
    const actionContent = actionMatch[1]

    // Extract individual tags with optional closing tags
    const titleMatch = /<title>([\s\S]*?)(<\/title>|$)/i.exec(actionContent)
    const parentSubsetIdMatch =
      /<parent_subset_id>([\s\S]*?)(<\/parent_subset_id>|$)/i.exec(
        actionContent,
      )
    const explanationMatch = /<explanation>([\s\S]*?)(<\/explanation>|$)/i.exec(
      actionContent,
    )

    const parentSubsetId = parentSubsetIdMatch?.[1].trim()
    const title = titleMatch ? titleMatch[1].trim() : ''
    const description = explanationMatch ? explanationMatch[1].trim() : ''

    // First, create the action object without the onSelect function
    const actionObj: Action = {
      title,
      description,
      parentSubsetId,
      index: actionsArray.length,
      nodeId,
      disabled: true,
      selected: false,
    }
    actionsArray.push(actionObj)
  }

  return { actionsArray }
}

export function getStatusTextByNodeType(
  nodeType: NodeType,
  experimentExecution?: STATUS,
): string | undefined {
  if (nodeType === 'actionSelector') {
    return 'Determining the best action to take'
  }

  if (nodeType === 'experimentInterpretation') {
    return 'Interpreting the experiment'
  }

  if (nodeType === 'evaluator') {
    return 'Evaluating how to continue'
  }

  if (nodeType === 'report') {
    return 'Reporting analysis'
  }

  if (nodeType === 'questionInterpretation') {
    return 'Interpreting the question'
  }

  // Case for experimentExecution
  if (experimentExecution === STATUS_EXECUTING) {
    return 'Executing the experiment'
  }

  if (experimentExecution === STATUS_WAITING) {
    return 'Waiting for the experiment to start'
  }

  if (experimentExecution === STATUS_ERROR) {
    return 'Error executing the experiment'
  }

  return undefined
}

export function mapNodeGroupsToOperations(
  groups?: NodeGroups,
  questionAnswered?: boolean,
  isReport: boolean = false,
): ButtonProps[] | undefined {
  if (groups === undefined) {
    return undefined
  }

  const operations: ButtonProps[] = []

  if (questionAnswered) {
    if (isReport) {
      if (groups.onDownloadReport !== undefined) {
        operations.push({
          variant: 'filled',
          color: 'primary',
          children: 'Download Report',
          onClick: groups.onDownloadReport,
        })
      }
      if (groups.onContinue !== undefined) {
        operations.push({
          variant: 'outlined',
          color: 'primary',
          children: 'Continue',
          onClick: groups.onContinue,
        })
      }
    } else {
      if (groups.onReport !== undefined) {
        operations.push({
          variant: 'filled',
          color: 'primary',
          children: 'Generate Report',
          onClick: groups.onReport,
        })
      }
      if (groups.onContinue !== undefined) {
        operations.push({
          variant: 'outlined',
          color: 'primary',
          children: 'Continue',
          onClick: groups.onContinue,
        })
      }
    }
  } else {
    if (isReport) {
      if (groups.onDownloadReport !== undefined) {
        operations.push({
          variant: 'filled',
          color: 'primary',
          children: 'Download Report',
          onClick: groups.onDownloadReport,
        })
        if (groups.onContinue !== undefined) {
          operations.push({
            variant: 'outlined',
            color: 'primary',
            children: 'Continue',
            onClick: groups.onContinue,
          })
        }
      }
    } else {
      if (groups.onReport !== undefined) {
        operations.push({
          variant: 'outlined',
          color: 'primary',
          children: 'Generate Report',
          onClick: groups.onReport,
        })
      }
      if (groups.onContinue !== undefined) {
        operations.push({
          variant: 'filled',
          color: 'primary',
          children: 'Continue',
          onClick: groups.onContinue,
        })
      }
    }
  }

  if (groups.onRetry !== undefined) {
    operations.push({
      variant: (groups.onReport ?? groups.onContinue) ? 'outlined' : 'filled',
      color: 'secondary',
      children: 'Retry',
      onClick: groups.onRetry,
    })
  }

  if (groups.onEdit !== undefined) {
    operations.push({
      variant: 'outlined',
      color: 'secondary',
      children: 'Edit',
      onClick: groups.onEdit,
    })
  }

  if (groups.onCancel !== undefined) {
    operations.push({
      variant: 'outlined',
      color: 'gray',
      children: 'Cancel',
      onClick: groups.onCancel,
    })
  }

  return operations.length > 0 ? operations : undefined
}

export function mapNodesToDisplay(
  nodes: InternalNodeType[],
  currentNodes: InternalNodeType[],
  nodeGroups: NodeGroups,
  running: boolean,
  questionAnswered: boolean,
  dispatchReducer: ActionDispatch<[action: AnalysisAction]>,
  lastNode?: NodeType,
  autoMode?: boolean,
): Node[] {
  return [...nodes, ...currentNodes].map((node, index) => {
    const isLastNode = index === nodes.length + currentNodes.length - 1

    if (node.type === 'action') {
      return {
        ...node,
        operations: !isLastNode
          ? undefined
          : running
            ? mapNodeGroupsToOperations(
                {
                  ...nodeGroups,
                  onContinue: undefined,
                  onReport: undefined,
                },
                questionAnswered,
              )
            : mapNodeGroupsToOperations(
                {
                  ...nodeGroups,
                  onContinue: undefined,
                  onRetry: undefined,
                },
                questionAnswered,
              ),
        actions: node.actions.map((action) => ({
          ...action,
          onSelect: () =>
            dispatchReducer({
              type: 'CURRENT_ACTION',
              payload: {
                title: action.title,
                description: action.description,
                parentSubsetId: action.parentSubsetId ?? '',
                index: action.index,
                nodeId: index,
              },
            }),
        })),
      } as ActionNode
    }

    return {
      ...node,
      operations: !isLastNode
        ? undefined
        : running
          ? mapNodeGroupsToOperations(
              {
                ...nodeGroups,
                onContinue: undefined,
                onReport: undefined,
              },
              questionAnswered,
            )
          : mapNodeGroupsToOperations(
              {
                ...nodeGroups,
                onReport:
                  lastNode === 'report' && autoMode
                    ? undefined
                    : nodeGroups.onReport,
                onRetry: undefined,
              },
              questionAnswered,
              lastNode === 'report',
            ),
    } as PlainNode
  })
}

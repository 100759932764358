import { type ActionDispatch, useEffect, useState } from 'react'
import { Dispatch as ReduxDispatch } from 'redux'
import {
  dispatchAction,
  useAIFilter,
  UseAIFilterProps,
} from '../../form/fields/AISearchField/AISearchField'
import { useDispatch } from 'react-redux'
import { useInterval } from 'react-use'

import {
  getExperimentStatus,
  STATUS_DONE,
  STATUS_ERROR,
  STATUS_EXECUTING,
  STATUS_WAITING,
} from '@/api/providers/experiment'
import type { AnalysisAction } from './analysisReducer'
import type { AnalysisState } from './useAnalysis'
import type { MenuNewItemAction } from '@/store/namespaces/menu/types'
import type { ActionResponse } from '@/api/providers/llm'

async function pollExperimentStatus(
  folderId: string,
  experimentId: string,
  dispatchReducer: ActionDispatch<[action: AnalysisAction]>,
  running: boolean,
): Promise<boolean> {
  const status = await getExperimentStatus(experimentId, undefined, folderId)
  switch (status) {
    case STATUS_EXECUTING:
    case STATUS_WAITING:
      dispatchReducer({
        type: 'UPDATE_EXPERIMENT_NODE',
        payload: {
          status,
        },
      })
      return false
    case STATUS_ERROR:
      if (running) {
        dispatchReducer({
          type: 'ERROR_RUNNING_EXPERIMENT',
          payload: {
            error: 'Experiment failed',
          },
        })
        return true
      }
      return false
    case STATUS_DONE:
      if (running) {
        dispatchReducer({
          type: 'END_EXPERIMENT_AND_CONTINUE',
        })
        return true
      }
      return false
    default:
      return false
  }
}

async function executeAction(
  aiFilterValue: ActionResponse,
  currentAction: UseAIFilterProps,
  dispatch: ReduxDispatch<MenuNewItemAction>,
  dispatchReducer: ActionDispatch<[action: AnalysisAction]>,
) {
  const { experimentId, folderId } = await dispatchAction(
    currentAction.folderId,
    aiFilterValue,
    dispatch,
    'id_model' in aiFilterValue.body ? aiFilterValue.body.id_model : undefined,
    'folder_id' in aiFilterValue.body
      ? aiFilterValue.body.folder_id
      : undefined,
  )
  dispatchReducer({
    type: 'SET_RUNNING_EXPERIMENT',
    payload: {
      parentSubsetId: currentAction.folderId,
      folderId,
      type: aiFilterValue.action,
      experimentId,
      title: aiFilterValue.body.title,
      description: aiFilterValue.body.interpretation,
    },
  })
}

export function useAIExperiment(
  analysis: AnalysisState,
  dispatchReducer: ActionDispatch<[action: AnalysisAction]>,
): void {
  const [currentAction, setCurrentAction] = useState<UseAIFilterProps>({
    folderId: '',
    userQuery: '',
    experimentId: '',
  })
  const [dispatched, setDispatched] = useState(false)
  const aiFilter = useAIFilter(currentAction)
  const dispatch = useDispatch<ReduxDispatch<MenuNewItemAction>>()

  useEffect(() => {
    if (analysis.currentAction) {
      setCurrentAction({
        folderId: analysis.currentAction.parentSubsetId,
        userQuery: `[${analysis.currentAction.title}];${analysis.currentAction.description}`,
        experimentId: '',
      })
    }
  }, [analysis.currentAction])

  useEffect(() => {
    if (aiFilter.value === undefined) {
      return
    }
    if (!analysis.running && !analysis.currentAction) {
      return
    }
    executeAction(
      aiFilter.value,
      currentAction,
      dispatch,
      dispatchReducer,
    ).then(() => {
      setDispatched(false)
    })
  }, [aiFilter.value])

  useInterval(() => {
    if (
      analysis.currentExperiment === undefined ||
      analysis.pendingAction ||
      analysis.lastNode !== 'experimentExecution' ||
      dispatched
    ) {
      return
    }

    pollExperimentStatus(
      analysis.currentExperiment.folderId,
      analysis.currentExperiment.experimentId,
      dispatchReducer,
      analysis.currentExperiment.running,
    ).then((isDispatched) => {
      if (isDispatched) {
        setDispatched(true)
      }
    })
  }, 5000)
}

import { httpServices } from '../lib/http'
import { memoize } from 'lodash'
import { getStoreId } from './store'
import { LLM_URL } from '../../constants/env'

export const filterToText = memoize(
  async (
    appId: string,
    filter: object,
    filterType: 'Patients Filter' | 'Episodes Filter' | 'Assessment Filter',
    language: string,
    datasetId: string,
  ): Promise<{ result: { text: string; details: string }; error?: string }> => {
    const doc = await (
      await httpServices(appId)
    ).post<{ text: string; details: string }>(`${LLM_URL}/llm/filter_to_text`, {
      filter,
      filter_type: filterType,
      language,
      dataset_id: datasetId,
    })
    if (doc.status !== 200) {
      console.error(doc.data)
      return {
        result: { text: '', details: '' },
        error: 'Filter to text error: ' + JSON.stringify(doc.data),
      }
    }
    return { result: doc.data }
  },
  (appId, filter, language) => `${appId}-${JSON.stringify(filter)}-${language}`,
)

interface BaseResponse {
  title: string
  details: string
  interpretation: string
}

interface ClusteringActionResponse extends BaseResponse {
  clustering: {
    num_clusters: number
    count_variables: string[]
    supplementary_variables: string[]
  }
}

interface UserVariableActionResponse extends BaseResponse {
  filter: object
  id_model: string
}

interface CompareGraphActionResponse extends BaseResponse {
  folder_id: string
  compare_graph: { min_support: number; count_variable: string[] }
}

interface FilterResponse {
  action: 'Patients Filter' | 'Episodes Filter' | 'Assessment Filter'
  body: BaseResponse & { filter: object }
}

interface AnalysisResponse {
  action: 'Clustering'
  body: ClusteringActionResponse
}

interface UserVariableResponse {
  action: 'User Variable'
  body: UserVariableActionResponse
}

interface CompareGraphResponse {
  action: 'Compare Graph'
  body: CompareGraphActionResponse
}

export type ActionResponse =
  | FilterResponse
  | AnalysisResponse
  | UserVariableResponse
  | CompareGraphResponse

// Create a non-memoizing version first
const textToCaseMixActionRaw = async (
  appId: string,
  text: string,
  language: string,
  datasetId: string,
  folderId: string,
  experimentId: string,
): Promise<{ result?: ActionResponse; error?: string }> => {
  const doc = await (
    await httpServices(appId)
  ).post<ActionResponse>(`${LLM_URL}/llm/text_to_case_mix_action`, {
    text,
    language,
    dataset_id: datasetId,
    folder_id: folderId,
    store_id: getStoreId(appId),
    selected_experiment: experimentId,
  })
  if (doc.status !== 200) {
    return { error: String(doc.status) }
  }
  return { result: doc.data }
}

// The memoized version that only caches successful results
const memoizedTextToCaseMixAction = memoize(
  async (
    appId: string,
    text: string,
    language: string,
    datasetId: string,
    folderId: string,
    experimentId: string,
  ): Promise<{ result: ActionResponse }> => {
    const response = await textToCaseMixActionRaw(
      appId,
      text,
      language,
      datasetId,
      folderId,
      experimentId,
    )

    // If there's an error, throw to prevent memoization
    if (response.error) {
      throw new Error(response.error)
    }

    return { result: response.result! }
  },
  (appId, text, language, datasetId, folderId, experimentId) =>
    `${appId}-${text}-${language}-${datasetId}-${folderId}-${experimentId}`,
)

// The exported function that catches thrown errors from the memoized version
export const textToCaseMixAction = async (
  appId: string,
  text: string,
  language: string,
  datasetId: string,
  folderId: string,
  experimentId: string,
): Promise<{ result?: ActionResponse; error?: string }> => {
  try {
    return await memoizedTextToCaseMixAction(
      appId,
      text,
      language,
      datasetId,
      folderId,
      experimentId,
    )
  } catch (error) {
    // If an error was thrown, it means we had an error response that shouldn't be memoized
    return { error: error instanceof Error ? error.message : String(error) }
  }
}

const MAX_SIZE_BYTES = 1 * 1024 * 1024

export const speechToText = async (
  appId: string,
  audio: Blob,
  language: string,
): Promise<{ result: string; error?: string }> => {
  const formData = new FormData()
  if (audio.size > MAX_SIZE_BYTES) {
    return { result: '', error: 'error' }
  }
  formData.append('file', audio, 'audio_recording.mp3')
  const doc = await (
    await httpServices(appId)
  ).post<{ text: string }>(
    `${LLM_URL}/llm/speech_to_text?language=${language}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  )
  if (doc.status !== 200) {
    return { result: '', error: 'error' }
  }
  return { result: doc.data.text }
}

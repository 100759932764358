import { useEffect, useReducer } from 'react'
import type { GraphLogs, GraphState } from './HistoryMenu'

import { PipelineType, type StreamGenerator } from '@/api/providers/graph'
import type {
  Node,
  PlainNode,
  ActionNode as ActionNodeType,
} from '@amalfi-analytics/ui/components/NodeGraph'
import { reducer } from './analysisReducer'
import { useAIExperiment } from './useAIExperiment'
import { downloadPdf } from './markdown_to_pdf'
import { mapNodesToDisplay } from './analysisUtils'

interface useAnalysisReturn {
  nodes: Node[]
  setGraph: (graphState: GraphState, logs: GraphLogs[]) => void
}

export interface NodeGroups {
  onEdit?: () => void
  onReport?: () => void
  onCancel?: () => void
  onContinue?: () => void
  onRetry?: () => void
  onDownloadReport?: () => Promise<void>
}

export type NodeType =
  | 'actionSelector'
  | 'experimentInterpretation'
  | 'experimentExecution'
  | 'evaluator'
  | 'report'
  | 'questionInterpretation'

export interface AnalysisState {
  nodes: InternalNodeType[]
  currentNodes: InternalNodeType[]
  question: string
  abortController?: AbortController
  projectId: string
  running: boolean
  streamPromise?: Promise<StreamGenerator>
  stream?: StreamGenerator
  accumulator: string
  graphStateId?: string
  questionAnswered: boolean
  currentExperiment?: {
    parentSubsetId: string
    folderId: string
    experimentId: string
    title: string
    description: string
    type: PipelineType
    running: boolean
  }
  lastNode?: NodeType
  pendingAction?: boolean
  currentAction?: {
    title: string
    description: string
    parentSubsetId: string
  }
}

export type Action = {
  title: string
  description: string
  parentSubsetId?: string
  index: number
  nodeId: number
  selected?: boolean
  disabled?: boolean
}

export interface ActionNode extends ActionNodeType {
  actions: Action[]
}

export type InternalNodeType = PlainNode | ActionNode

interface useAnalysisProps {
  projectId?: string
  autoMode: boolean
  question: string
  questionIsSubmitted: boolean
  setQuestionIsSubmitted: (questionIsSubmitted: boolean) => void
  resetQuestion: () => void
}

export const useAnalysis = ({
  projectId,
  autoMode,
  question,
  questionIsSubmitted,
  setQuestionIsSubmitted,
  resetQuestion,
}: useAnalysisProps): useAnalysisReturn => {
  const defaultState: AnalysisState = {
    nodes: [],
    currentNodes: [],
    question: '',
    projectId: '',
    running: false,
    accumulator: '',
    questionAnswered: false,
  }
  const [analysis, dispatchReducer] = useReducer(reducer, defaultState)
  useAIExperiment(analysis, dispatchReducer)

  useEffect(() => {
    if (questionIsSubmitted && projectId) {
      dispatchReducer({
        type: 'NEW_ANALYSIS',
        payload: {
          projectId,
          question,
        },
      })
    }
  }, [questionIsSubmitted, projectId])

  useEffect(() => {
    if (!analysis.pendingAction) return
    switch (analysis.lastNode) {
      case 'experimentInterpretation':
      case 'questionInterpretation':
        dispatchReducer({ type: 'CONTINUE_ANALYSIS' })
        break
      case 'evaluator':
        if (analysis.questionAnswered) {
          dispatchReducer({ type: 'REPORT_ANALYSIS' })
        } else {
          dispatchReducer({ type: 'CONTINUE_ANALYSIS' })
        }
        break
      case 'experimentExecution':
        dispatchReducer({ type: 'INTERPRET_EXPERIMENT' })
        break
      case 'report':
        dispatchReducer({ type: 'CONTINUE_ANALYSIS' })
        break
    }
  }, [analysis.lastNode, analysis.pendingAction])

  useEffect(() => {
    if (analysis.streamPromise !== undefined) {
      const processStream = async (streamPromise: Promise<StreamGenerator>) => {
        const stream = await streamPromise
        dispatchReducer({
          type: 'SET_STREAM',
          payload: { stream },
        })
      }
      processStream(analysis.streamPromise)
    }
  }, [analysis.streamPromise])

  useEffect(() => {
    if (analysis.stream === undefined) {
      return
    }
    const processStream = async (stream: StreamGenerator) => {
      const chunk = await stream.next()
      if (chunk.value) {
        dispatchReducer({
          type: 'NEW_CHUNK',
          payload: { chunk: chunk.value },
        })
      } else {
        dispatchReducer({ type: 'END_STREAM', payload: { autoMode } })
      }
    }
    processStream(analysis.stream)
  }, [analysis.accumulator, analysis.stream])

  const setGraph = (graphState: GraphState, logs: GraphLogs[]) => {
    dispatchReducer({
      type: 'SET_GRAPH',
      payload: { graphState, logs },
    })
  }

  const nodeGroups: NodeGroups = {
    onEdit: () => {
      setQuestionIsSubmitted(false)
    },
    onCancel: () => {
      dispatchReducer({ type: 'RESET_ANALYSIS' })
      resetQuestion()
    },
    onReport: () => dispatchReducer({ type: 'REPORT_ANALYSIS' }),
    onContinue: () => dispatchReducer({ type: 'CONTINUE_ANALYSIS' }),
    onRetry: () => dispatchReducer({ type: 'RETRY_NODE' }),
    onDownloadReport: async () => {
      if (analysis.nodes.length === 0) {
        return
      }
      const lastNode = analysis.nodes[analysis.nodes.length - 1]
      await downloadPdf(lastNode.title ?? '', lastNode.description ?? '')
    },
  }

  useEffect(() => {
    if (
      !autoMode ||
      analysis.nodes.length === 0 ||
      analysis.currentAction !== undefined
    ) {
      return
    }
    const targetNode = analysis.nodes[analysis.nodes.length - 1] as
      | InternalNodeType
      | undefined
    if (
      !targetNode ||
      targetNode.type !== 'action' ||
      targetNode.actions.length === 0
    ) {
      return
    }
    const targetAction = targetNode.actions[0]
    dispatchReducer({
      type: 'CURRENT_ACTION',
      payload: {
        title: targetAction.title ?? '',
        description: targetAction.description ?? '',
        parentSubsetId: targetAction.parentSubsetId ?? '',
        index: targetAction.index,
        nodeId: analysis.nodes.length - 1,
      },
    })
  }, [analysis.nodes])

  const nodes = mapNodesToDisplay(
    analysis.nodes,
    analysis.currentNodes,
    nodeGroups,
    analysis.running,
    analysis.questionAnswered,
    dispatchReducer,
    analysis.lastNode,
    autoMode,
  )

  return { nodes, setGraph }
}
